export const useOnLogout = (fn: () => Promise<any>) => {
  const authStore = useAuthStore()

  watch(
    () => authStore.token,
    async () => {
      if (!authStore.token) {
        await fn()
      }
    }
  )
}
